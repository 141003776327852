:root {
    --color-primary: #006400;
    --color-secondary: #509250 ;
    --bg-menu: #006400 ;
}

@font-face {
    font-family: 'BaiJamjuree-Regular';
    /*a name to be used later*/
    src: url('../src/res/fonts/BaiJamjuree-Regular.ttf');
    /*URL to font*/
}

body {
    background-color: #f3f3f3;
    margin: 0;
    font-family: 'BaiJamjuree-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
}

.mr-10 {
    margin-right: 10px;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.no-bg {
    background-color: transparent !important;

    &:hover {
        background-color: transparent !important;
    }
}

.tbl-image {
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
}

// .ant-table {
//     min-height: 500px;
// }

@keyframes bounceIn {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0px);
    }

    40% {
        transform: translateY(-40px);
        opacity: 1;
    }

    60% {
        transform: translateY(-15px);
        opacity: 1;
    }
}

.auth_layout {
    background: url(../src/res/images/BG-Login3.jpg) no-repeat center center fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bounceIn 1.9s ease-out;
}




.ant-layout-sider {
    background-color: var(--bg-menu);
}

.ant-layout {
    min-height: 100vh !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: var(--bg-menu);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--color-secondary);
}

.ant-btn-primary {
    border-color: var(--color-primary);
    background: var(--color-primary);
}

.ant-btn-background-ghost.ant-btn-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    border-color: var(--color-secondary);
    background: var(--color-secondary);
}

.ant-pagination-item-active {
    border-color: var(--color-primary);
    background: var(--color-primary);
}

.ant-pagination-item-active a {
    color: #fff;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
    border-color: var(--color-secondary);
    background: var(--color-secondary);
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
    color: #fff;
}

.ant-card {
    margin-bottom: 15px !important;
}

.ant-btn,
.ant-card,
.ant-card-bordered {
    border-radius: 6px !important;
}

.ant-modal-root .ant-modal-content {
    border-radius: 6px !important;
    overflow: hidden;
}

.form-search {
    .ant-card-body {
        padding-bottom: 0;
    }

    .ant-form-item-label label {
        font-weight: 500;
    }
}

.ant-breadcrumb ol {
    list-style: none;
}

.ant-table-thead>tr>th.ant-table-cell {
    background-color: #f2f6f8;
    font-weight: bold;
}

.ant-breadcrumb-link {
    font-size: 12px;
}

td>div>img.ant-image-img {
    object-fit: cover;
}

li.ant-pagination-total-text {
    color: #aab0c7;
    font-size: 14px;
}

.ant-pagination.mini .ant-pagination-item {
    min-width: 33px;
    height: 33px;
    margin: 0;
    border-radius: 4px;
    line-height: 33px;
}

.ant-modal-footer {
    padding: 5px 16px 20px 16px;
    text-align: right;
    background: transparent;
    border-top: none;
    border-radius: 0 0 2px 2px;
}

.buttonAddNew {
    height: 40px;
    border-radius: 3px;
}

.image-customer img.ant-image-img {
    border-radius: 6px;
    object-fit: contain;
    border: 1px solid #e7e7e7;
}

.image-search {
    width: 17px;
    object-fit: cover;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
    border-radius: 3px;
}

.label-price .ant-form-item-label>label {
    height: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.avatar-news .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 210px;
    border-radius: 6px;
}

.avatar-news .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}

.ck-editor__editable_inline {
    min-height: 450px;
}

.example-spin {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

.input-phone-staff .react-tel-input .form-control {
    width: 100% !important;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #d9d9d9;
}

.input-phone-staff .react-tel-input .selected-flag {
    width: 40px;
}

.sufix-number {
    background: #e5edf1;
    border-radius: 3px;
}

.checkbox-right .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: unset !important;
}

.table-status-active {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}

.table-status-refuse {
    border: 1px solid red;
    border-radius: 3px;
    cursor: pointer;
    color: red;
    padding: 5px 0px;
    text-align: center;
}

.table-status-inactive {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}

.table-status-approved {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}

.table-status-pending {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}

.logoCms {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 29px;
    padding-top: 30px;
    font-weight: bold;
    cursor: pointer;
}

.logoImageCms {
    width: 60px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
}

.mutiple-image.ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 90px;
    margin-right: 15px;
    position: relative;
    top: -45px;
}

.mutiple-image-nodata.ant-upload-picture-card-wrapper {
    top: 0 !important;
}

.mutiple-image.ant-upload-picture-card-wrapper .loadingView {
    position: absolute;
    bottom: 10px;
    left: 32%;
}

.mutiple-image .ant-upload.ant-upload-select-picture-card {
    width: 100px;
    height: 100px;
    border-radius: 6px;
}

.mutiple-image .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}

.list-multiple-image {
    height: 120px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 15px;
    margin-top: 5px;
}

.multiple-image-item {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    position: relative;
    margin-right: 15px;
    display: inline-block;
}

.multiple-image-item .multiple-image-item-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.multiple-image-item .btn-close img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
}

.multiple-image-item .btn-close {
    cursor: pointer;
}

// upload image component
.avatar-news {
    min-width: 200px;
    max-width: 100%;
}

.ant-layout-sider.ant-layout-sider-dark {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
}

.ant-layout-sider::-webkit-scrollbar {
    display: none;
}

.render-action-style {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.render-action-style>span {
    margin: 0 2px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-75 {
    margin-top: 75px;
}

.titleNonRequired {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.titleRequired {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.titleRequired::after {
    content: ' *';
    color: red;
}

.btnSearch {
    margin-top: 16px;
}

.ant-form-item-label {
    label {
        font-size: 16px;
        text-transform: capitalize;
    }
}

.PENDING {
    color: red;
}

.ADDED {}

.CANCEL {
    color: #f24e1e;
}

.APPROVE {}

.SHIPPING {
    color: #ffba07;
}

.PAI {
    color: #1ed662;
}

.delivery {
    width: 100%;

    .ant-table {
        min-height: auto;
        margin-bottom: 30px;

        .ant-form-item-has-success,
        .ant-form-item {
            margin-bottom: 0px;
        }
    }

    .wrap-order-info {
        margin: 0px;
        width: 100%;
        display: inline-block;
        list-style: none;
        margin-bottom: 30px;

        li {
            font-size: 15px;
        }
    }

    .info {
        font-size: 17px;
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
    }

    .info-item {
        margin-bottom: 5px;
        font-size: 16px;
    }
}

.wrap-return {
    margin: 0px;
    width: 100%;
    display: inline-block;
    list-style: none;

    .item {
        width: 100%;
        float: left;

        .title {
            font-size: 18px;
            margin-bottom: 5px;
        }

        .wrap-return-child {
            padding-left: 30px;

            .item-child {
                .title-child {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.wrap-confirm-product {
    position: relative;

    .button-confirm {
        position: absolute;
        right: 0;
        top: 0px;
    }

    .ant-form-item-label label {
        font-size: 15px;
    }
}

.wrap-productionPlan {
    .wrap-session {
        height: auto;

        .ant-table {
            min-height: auto;
        }
    }
}

.table-auto {
    .ant-table {
        min-height: auto !important;
    }
}

.mb-10 {
    margin-bottom: 10px;
}

.d-block {
    display: block;
}

.pl-10 {
    padding-left: 10px;
}

.w-full {
    width: 100%;
}

.flex {
    display: flex;
}

.w-full {
    width: 100%;
}

.relative {
    position: relative;
}

.object-cover {
    object-fit: cover !important;
}

.items-center {
    align-items: center;
    justify-content: center;
}

.modal-add-to-cart {
    .ant-modal-close-icon {
        top: -10px;
        position: relative;
    }

    .ant-modal-body {
        padding: 12px;

        .wrap-content {
            .product-name {
                font-size: 24px;
                line-height: 36px;
                font-weight: 700;
            }

            .short-desc {
                font-size: 20px;
                line-height: 30px;
                color: rgb(130 130 130);
            }

            .color-title {
                font-size: 20px;
                line-height: 30px;
            }

            .wrap-product {
                border-radius: 8px;
                background-color: #f2f2f2;

                img {
                    max-width: 240px;
                    min-height: 240px;
                    border-radius: 8px;
                }

                .top-title {
                    padding: 24px;
                }
            }

            .yourSize {
                right: 8px;
                bottom: 8px;
                background-color: #47817e;
                border-radius: 20px;
                font-size: 12px;
            }

            .btn-primary-full {
                cursor: pointer;
                --tw-bg-opacity: 1;
                background-color: rgb(182 30 66);
                width: 160px;
                height: 40px;
                color: white;
                border: none;
                border-radius: 8px;
            }

            .add-handle {
                width: 100%;
                align-items: center;

                button {
                    margin-top: 8px;
                }
            }
        }

        .coutinue.btn-primary-full {
            background-color: #fff;
            color: #000;
            border: 1px solid #000;
        }
    }

    .wrap-color {
        .item {
            cursor: pointer;
            position: relative;
            width: auto;
            margin-right: 1%;
            font-size: 14px;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 6px 10px 6px 34px;

            &.active {
                border: 1px solid #c31a1a;
            }

            .bg-color {
                .img {
                    object-fit: cover;
                    width: 20px;
                    height: 20px;
                }

                left: 7px;
                top: 7px;
            }

            list-style-type: none;
            float: left;
        }

        display: inline-block;
        width: 100%;
    }

    .wrap-size {
        list-style-type: none;

        .item {
            width: auto;
            margin-right: 1%;
            font-size: 14px;
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 6px 10px 6px 10px;

            &.active {
                border: 1px solid #c31a1a;
            }

            float: left;
            cursor: pointer;
        }
    }
}

.remain {
    line-height: 36px;
    margin-left: 12px;
}

.note_input {
    position: relative;

    p {
        color: #c5c5c5;
        font-size: 16px;
        position: absolute;
        left: 45px;
    }
}

.note_inputs {
    position: relative;

    p {
        color: #c5c5c5;
        font-size: 16px;
        position: absolute;
        left: 45px;
        margin-top: 5px;
    }
}

.ant-checkbox+span {
    white-space: nowrap;
}

.ck ul {
    margin-left: 20px;
}

.ck p {
    margin-bottom: 0;
}

.ck-content {
    border: 1px solid #c7c7c7 !important;
}

.mb0 {
    margin-bottom: 0;
}

.pointer {
    cursor: pointer;
}

.no-data {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #847E80;
    margin-top: 50px;
}

.bold {
    font-weight: 600;
}

.table-row-light {
    background-color: var(--color-primary);

    &:hover td {
        background-color: var(--color-primary) !important;
    }
}

.table-row-dark {
    background-color: #a7a7a7;

    &:hover td {
        background-color: #a7a7a7 !important;
    }
}

.font-large {
    // font-size: 24px;
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }
}

.task-focus {
    z-index: 999 !important;
    transform: scale(1.1);
    transition: all 0.4s;
}